import React from "react";
import { sideToppPropsType } from './type.d'
import './sideTopp.scss'

function SideTopp(props: Readonly<sideToppPropsType>) {
    const {title, ingress, image, imageAlt, center} = props;
    return (
        <div className={`flex flex-col py-md tablet:py-lg desktop:py-xl ${center ? "items-center" : ""}`}>
            <div className={`flex flex-row ${ingress ? "mb-lg" : ""}`}>
                {image &&
                    <img className={`mr-md ${center ? "h-16" : "h-20 tablet:h-28"}`} src={image} alt={imageAlt}/>
                }
                <h1 className="title flex items-center text-xxxl font-bold break-normal hyphens-auto">{title}</h1>
            </div>
            <div
                className={`text-md break-normal hyphens-auto ${center ? "text-center w-11/12 desktop:w-4/5" : ""}`}>{ingress}</div>
        </div>
    )
}

export default (props: Readonly<sideToppPropsType>) => <SideTopp {...props} />
